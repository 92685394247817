import { ImageLoaderProps } from 'next/image';

const normalizeSrc = (src: string) => {
    return src[0] === '/' ? src.slice(1) : src;
};

export const contentfulLoader = ({ src, width, quality }: ImageLoaderProps) => {
    const params = [`w=${width}`, `q=${quality ?? 80}`, `fm=avif`];

    const paramsString = params.join('&');

    return `${normalizeSrc(src)}?${paramsString}`;
};
