import React from 'react';

type GoogleTagManagerProps = {
    googleTagManagerId?: string;
};

export const GoogleTagManager = ({ googleTagManagerId }: GoogleTagManagerProps) => {
    return (
        <>
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://tracking.kompan.com/ns.html?id=${googleTagManagerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
            ></noscript>
        </>
    );
};

export const GoogleTagManagerScript = ({ googleTagManagerId }: GoogleTagManagerProps) => {
    return `
        (function(w,d,s,l,i){
            w[l]=w[l]||[]; w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
            j.async=true; j.src='https://tracking.kompan.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
        })(window, document, 'script', 'dataLayer', '${googleTagManagerId}');
        `;
};
