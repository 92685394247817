import React, { useState, useEffect, useMemo, useRef } from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { GlobalStyles, ThemeProvider } from '$theme';
import { RequestProvider } from '$shared/utils';
import { MarketProvider } from '~/shared/utils/market';
import { getLocaleDirection } from '~/shared/utils/translation';
import { DynamicPageProps } from '~/templates/pages';

import { Toaster } from 'react-hot-toast';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GoogleTagManager, NavigatingSpinner } from '~/shared/components';
import { Hydrate } from 'react-query';
import { P410StaticNotFoundPage } from '~/templates/pages/components/P410StaticNotFoundPage';
import { applicationInsightsTelemetryFilter } from '~/shared/utils/applicationInsights/applicationInsightsTelemetryFilter';
import { BotDetectorWrapper } from '$shared/utils/bot-detector';
import { MyKompanContextProvider } from '~/shared/utils/myKompanContext/MyKompanContextProvider';
//import { AuthenticationProvider } from '~/shared/utils/authentication/AuthenticationProvider';
import dynamic from 'next/dynamic';
import { VerificationState } from '~/shared/utils/authentication/AuthenticationProvider';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent/useCookieConsent';

const AuthenticationProvider = dynamic(
    () =>
        import('~/shared/utils/authentication/AuthenticationProvider').then(
            (mod) => mod.AuthenticationProvider,
        ),
    {
        ssr: false,
    },
);

type CustomAppProps<P = unknown> = {
    pageProps: P | undefined;
} & Omit<AppProps<P>, 'pageProps'>;

export const App = ({ Component, pageProps }: CustomAppProps<DynamicPageProps>): JSX.Element => {
    const reactPlugin = useMemo(() => new ReactPlugin(), []);
    const [verificationState, setVerificationState] = useState<VerificationState>('READY');

    const { settings, frame, page } = pageProps ?? {};
    const { isMyKompan, aiInstrumentationKey } = settings ?? {};
    const { culture = 'en', market = 'INT' } = page ?? {};

    const { consent } = useCookieConsent();

    const ConditionalAuthenticationProvider = useMemo(() => {
        if (isMyKompan) {
            return ({ children }: React.PropsWithChildren) => (
                <AuthenticationProvider
                    frame={pageProps?.frame}
                    onVerificationStateChange={(state) => setVerificationState(state)}
                >
                    {children}
                </AuthenticationProvider>
            );
        }
        return ({ children }: React.PropsWithChildren) => <>{children}</>;
    }, [isMyKompan, frame, setVerificationState]);

    const appInsightsRef = useRef<ApplicationInsights | null>(null);

    useEffect(() => {
        // Register applicationInsights
        if (process.env.NODE_ENV === 'production' && aiInstrumentationKey) {
            if (!appInsightsRef.current) {
                appInsightsRef.current = new ApplicationInsights({
                    config: {
                        instrumentationKey: aiInstrumentationKey,
                        enableAutoRouteTracking: true,
                        extensions: [reactPlugin],
                        disableCookiesUsage: !(consent?.statistics ?? false),
                    },
                });
                appInsightsRef.current.addTelemetryInitializer(applicationInsightsTelemetryFilter);
                appInsightsRef.current.loadAppInsights();
            } else {
                appInsightsRef.current.config.disableCookiesUsage = !(consent?.statistics ?? false);
            }
        }
    }, [consent]);

    useEffect(() => {
        // to update the lang and dir on HTML
        if (pageProps?.page?.culture) {
            document.documentElement.lang = pageProps?.page?.culture || 'en';
            document.documentElement.dir = getLocaleDirection(pageProps?.page?.culture || 'en');
        }
    }, [pageProps?.page?.culture]);

    if (!pageProps?.page) {
        return <></>;
    }

    if (pageProps?.page.type == 'p410StaticNotFoundPage') {
        return <P410StaticNotFoundPage {...pageProps?.page} />;
    }

    return (
        <>
            {frame?.settings?.googleTagManagerId && (
                <GoogleTagManager googleTagManagerId={frame.settings.googleTagManagerId} />
            )}
            <AppInsightsContext.Provider value={reactPlugin}>
                <ConditionalAuthenticationProvider>
                    <RequestProvider>
                        <Hydrate state={pageProps.dehydratedState}>
                            <BotDetectorWrapper>
                                <MyKompanContextProvider isMyKompan={isMyKompan ?? false}>
                                    <ThemeProvider
                                        direction={getLocaleDirection(culture)}
                                        language={culture}
                                    >
                                        <GlobalStyles language={culture} />
                                        <MarketProvider value={{ culture, market }}>
                                            <NavigatingSpinner />
                                            <Toaster position="top-right" reverseOrder={false} />
                                            {(!isMyKompan || verificationState === 'READY') && (
                                                <Component {...pageProps} />
                                            )}
                                        </MarketProvider>
                                    </ThemeProvider>
                                </MyKompanContextProvider>
                            </BotDetectorWrapper>
                        </Hydrate>
                    </RequestProvider>
                </ConditionalAuthenticationProvider>
            </AppInsightsContext.Provider>
        </>
    );
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
    // eslint-disable-next-line no-console
    console.log('Webvitals', metric.name, Math.round(metric.value * 100) / 100, 'ms');
}

export default App;
